/**
 * Display helper class
 * @path: utilities/display
 */

.u-inline-block {
    display: inline-block;
}

.u-block {
    display: block !important;
} 