/**
 * Sass helper functions
 * @path: tools/functions
 */

/**
 * Get the value of the gutter per breakpoint and divide it by 2 
 *
 * @author Rianne Oosthoek
 * @param {string} $name - name of the breakpoint
 * @param {boolean} $half - if true, devide value by two
 * @return value for gutter
 */
@function get-gutter( $name, $half: true ) {
    @if $half {
        @return -zf-get-bp-val($grid-column-gutter, $name) / 2;
    } @else {
        @return -zf-get-bp-val($grid-column-gutter, $name);
    }
}