/**
 * reCaptcha
 * @oath: components/recaptcha
 */

.g-recaptcha{
    margin-top:3.5rem; 
    margin-left:calc(50% - 15rem);
    transition: opacity .7s ease-out, height .2s ease-out;
    iframe{
        transform-origin:0 0;
    }
}
