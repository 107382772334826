/**
 * Images
 * @path: components/images
 */

.c-images-wrapper{
    min-height:35rem;
    margin-top:2rem;
    position:relative;
    z-index:5;
    text-align:center;
}

.c-image-double{
    max-width:39.5rem;
    margin:auto;
    position:absolute;
    left:0;
    right:0;
    .c-image-top{
        width:70%;
        max-width:26.5rem;
        position:relative;
        z-index:15;
        left:20%;
        display:block;
    }
    .c-image-bottom{
        position:relative;
        z-index:10;
        top:-3rem;
        display:block;
    }
}
.c-image-single{
    .c-image-bottom{
        margin-top:4rem;
        position:relative;
        z-index:10;
        bottom:-10rem;
    }
}

// 520+
@include breakpoint(medium){
    .c-image-single{
        .c-image-bottom{
            bottom:-11rem;
        }
    }
}

// 960+
@include breakpoint(large){
    .c-images-wrapper{
        min-height:33rem;
        margin-top:0;
    }

    .c-image-single{
        .c-image-bottom{
            position:absolute;
            top:3rem;
            left:0;
            bottom:auto;
        }
    }
}