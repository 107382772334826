/**
 * Labels
 * @path: components/label
 */

.c-label {
    padding: $label-padding;
    font-family: $header-font-family;
    font-size: 1.8rem;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    display: inline-block;

    &.is-featured {
        position: absolute;
        z-index: 5;
        top: .8rem;
        right: .8rem;
        background-color: $quaternary-color;
    }
}