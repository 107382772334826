/**
 * Select2
 * @path: global/components/select2
 */
// Mixin for count of players
@mixin players(){
    // Loop through items from 2 till 50
    @for $i from 1 through 3 {
        // Loop through each child starting from the first and count + $i each time
        &:nth-child(1n+#{$i}) {
            .select2-selection__choice__remove::after{
                content:'  Speler #{1+$i}: ';
            }
        }
    }
}

// Frontend reservation
.select2-frontend-wrapper{
    .select2-selection--multiple{
        .select2-selection__choice{
            @include players;
            background-color:transparent;
            border-color:transparent;
            color:$primary-color;
        }
        .select2-selection__choice__remove{
            color:$primary-color;
            &::after{
                margin-left:.2rem;
                color:$primary-color;
                font-weight:normal;
            }
            &:hover{
                color:$primary-color;
            }
        }
    }
    .select2-results{
        .select2-results__option{
            &[aria-selected=true]{ 
                background-color:$secondary-color;
            }
        }
    } 
}

// Container
.select2-container{
    margin-bottom:$form-spacing;
    position:relative;   
    vertical-align:middle;
    display:inline-block;
}

// Focus
.select2-container--focus{
    // Multi
    .select2-selection--multiple{
        border:.1rem solid $input-border-focus;
        outline:0;
    }
}

// Open
.select2-container--open{
    &.select2-container--above{
        .select2-selection--single, .select2-selection--multiple{ 
            border-top-left-radius:0; 
            border-top-right-radius:0; 
        }
    }
    &.select2-container--below{
        .select2-selection--single, .select2-selection--multiple{ 
            border-bottom-left-radius:0; 
            border-bottom-right-radius:0; 
        }
    }
    // Single
    .select2-selection--single{
        .select2-selection__arrow{
            b{ 
                border-width:0 4px 5px 4px; 
                border-color:transparent transparent #888 transparent; 
            }
        }
    }
}

// Disabled
.select2-container--disabled{
    // Single
    .select2-selection--single{
        background-color:#eee;
        cursor:default;
        .select2-selection__clear{ 
            display:none; 
        }
    }
    // Multi
    .select2-selection--multiple{
        background-color:#eee;
        cursor:default;
    }
    .select2-selection__choice__remove{ 
        display:none; 
    }
}

// Single-select
.select2-selection--single{
    height:28px;
    border:1px solid #aaaaaa; 
    background-color:$white;
    cursor:pointer;
    display:block;
    user-select:none;
    .select2-selection__rendered{
        padding-left:8px; 
        padding-right:20px;        
        line-height:28px; 
        color:#444444; 
        text-overflow:ellipsis; 
        white-space:nowrap;
        overflow:hidden; 
        display:block;
    }
    .select2-selection__placeholder{ 
        color:#999999; 
    }
    .select2-selection__clear{ 
        position:relative; 
        float:right;
        cursor:pointer; 
        font-weight:bold;
    }
    .select2-selection__arrow{
        width:20px; 
        height:26px;
        position:absolute; 
        top:1px; 
        right:1px;
        b{
            width:0; 
            height:0; 
            margin-top:-2px; 
            margin-left:-4px;
            position:absolute; 
            top:50%; 
            left:50%;            
            border-width:5px 4px 0 4px; 
            border-style:solid; 
            border-color:#888888 transparent transparent transparent;
        }
    }
}

// Multi-select
.select2-selection--multiple{
    min-height:42px;
    border:1px solid #aaaaaa; 
    background-color:$white;
    cursor:text;
    display:block;
    user-select:none;
    .select2-selection__rendered{
        width:100%; 
        margin:0; 
        padding:0 5px;
        text-overflow:ellipsis; 
        white-space:nowrap; 
        list-style-type:none;
        overflow:hidden; 
        li{ 
            list-style-type:none; 
        }
    }
    .select2-selection__placeholder{
        margin-top:5px;
        float:left;
        color:#999999;
    }
    .select2-selection__clear{
        margin-top:5px; 
        margin-right:10px;
        float:right;
        font-weight:bold;
        cursor:pointer;
    }
    .select2-selection__choice{
        margin-top:5px; 
        margin-right:5px; 
        padding:0 5px;
        float:left;
        background-color:$primary-color; 
        border:.1rem solid $primary-color;         
        color:$white; 
        cursor:default;
        clear:both;
    }

    .select2-selection__choice__remove{
        margin-right:2px;
        color:$white; 
        font-weight:bold;
        cursor:pointer;
        display:inline-block;
        &:hover{ 
            color:$white; 
        }
    }
}
.select2-search--inline{
    flex:1 1 100%;
    .select2-search__field{
        width:100% !important; 
        height:auto; 
        margin-top:9px; 
        margin-bottom:5px;
        padding:0;
        font-size:100%; 
        outline:0;
        line-height:1.6;
        border:none; 
        background:transparent; 
        box-shadow:none;
        -webkit-appearance:textfield;
        &::-webkit-search-cancel-button{
            -webkit-appearance:none;
        }
    }
}


// Dropdown
.select2-dropdown{
    width:100%;
    position:absolute; 
    z-index:1051; 
    left:-100000px;
    background-color:$white; 
    border:1px solid #aaaaaa;  
    display:block;
}
.select2-results{ 
    display:block; 
    & > .select2-results__options{
        max-height:200px;
        overflow-y:auto;
    }
    .select2-results__group{
        padding:6px;
        cursor:default;
        display:block;
    }
    .select2-results__options{
        width:100%;
        float:left;
        margin:0; 
        padding:0;
        list-style:none;
    }
    .select2-results__option{
        padding:6px;
        user-select:none;
        &[aria-selected]{ 
            cursor:pointer; 
        }
        &[role=group]{ 
            padding:0; 
        }
        &[aria-disabled=true]{ 
            color:#999999; 
        }
        &[aria-selected=true]{ 
            background-color:$primary-color;
            color:$white;
        }
        .select2-results__option{
            padding-left:1em;
            .select2-results__group{ 
                padding-left:0; 
            }
            .select2-results__option{ 
                margin-left:-1em; 
                padding-left:2em;
                .select2-results__option{ 
                    margin-left:-2em; 
                    padding-left:3em;
                    .select2-results__option{
                        margin-left:-3em; 
                        padding-left:4em;
                        .select2-results__option{
                            margin-left:-4em; 
                            padding-left:5em;
                            .select2-results__option{ 
                                margin-left:-5em; 
                                padding-left:6em; 
                            }
                        }
                    }
                }
            }
        }
    }
}
.select2-results__option--highlighted[aria-selected]{
    color:$primary-color !important;
    background-color:$light-gray !important;
}
.select2-container--open .select2-dropdown{ 
    left:0; 
}
.select2-container--open .select2-dropdown--above{ 
    border-bottom:none; 
    border-bottom-left-radius:0; 
    border-bottom-right-radius:0; 
}
.select2-container--open .select2-dropdown--below{ 
    border-top:none; 
    border-top-left-radius:0; 
    border-top-right-radius:0;
}
.select2-search--dropdown{
    padding:4px;
    display:block;
    &.select2-search--hide{ 
        display:none; 
    }
    .select2-search__field{
        width:100%; 
        padding:4px;
        border:1px solid #aaaaaa;       
        &::-webkit-search-cancel-button{ 
            -webkit-appearance:none; 
        }
    }
}

// Close
.select2-close-mask{
    min-width:100%; 
    width:auto; 
    min-height:100%; 
    height:auto; 
    margin:0; 
    padding:0;
    position:fixed; 
    z-index:99;
    left:0; 
    top:0;
    border:0;
    opacity:0; 
    display:block;
    // styles required for IE to work
    background-color:$white;
    filter:alpha(opacity=0);
}

// Hidden accessible
.select2-hidden-accessible{
    width:1px !important; 
    height:1px !important; 
    margin:-1px !important; 
    padding:0 !important;
    position:absolute !important;
    border:0 !important;
    overflow:hidden !important;
    clip:rect(0 0 0 0) !important;
}
