/**
 * Slider
 * @oath: components/slider
 */

.c-slider-wrapper{
    position:relative;
    z-index:1;
    overflow:hidden;
    &.header-slider{
        .c-slide{
            @include header-overlay;
        }
    }
}

.c-slide{
    width:100%;
    height:100%; 
    min-height:1px;
    float:left;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
    display:flex;
    &:focus{ outline:0; }
    &.no-flex{ display:block; }
}

.c-slide-image{
    width:10rem;
    height:10rem;
    position:relative;
    margin:0 auto 1.5rem auto;
    img{
        position:absolute;
        top:50%;
        left:0;
        transform:translateY(-50%);
    }
}

.c-slider-arrow{
    position:absolute;
    top:50%;
    z-index:10;
    display:none !important;
    &.arrow-next{
        transform:rotate(-90deg) translateY(-50%);
        .c-icon::before{
            font-size:4rem;
        }
    }
    &.arrow-prev{
        transform:rotate(90deg) translateY(-50%);
        .c-icon::before{
            font-size:4rem;
        }
    }
}

// Plugin
.slick-list{
    height:100%;
    overflow:hidden;
}

.slick-track{
    height:100%;
    display:block;
    overflow:hidden;
}

.slick-dots{
    margin:0 auto;
    position:absolute; 
    z-index:5; 
    bottom:3rem; 
    left:0; 
    right:0;
    list-style-type:none; 
    text-align:center; 
    line-height:0;
    li{
        width:2.5rem; 
        height:2.5rem;
        display:inline-block;
        &.slick-active{
            button::before{ background-color:$white;}
        }
    }
    button{
        width:100%; 
        height:100%;
        position:relative;
        font-size:0; 
        line-height:0;
        cursor:pointer;
        display:block;
        &:focus{ outline:0; }
        &::before{
            width:1.2rem; 
            height:1.2rem; 
            margin:auto;
            position:absolute; 
            top:50%; 
            left:50%;
            border-radius:10rem;
            background-color:$black;
            content:''; 
            transform:translate3d(-50%, -50%, 0);
            transition:background-color .3s ease-in-out;
        }
    }
}

@include breakpoint(medium) {
    .c-slider-arrow{
        display:block !important;
        &.arrow-next{
            right:-7rem;
        }
        &.arrow-prev{
            left:-7rem;
        }
    }
}