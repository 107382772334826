/**
 * Loader
 */
// Pulsing circle
@keyframes loader{
    0%{ transform:scale(0); }
    100%{
        transform:scale(1.0);
        opacity:0;
    }
}

.c-loader{
    @extend .u-margin-vertical;
    width:4rem;
    height:4rem;
    margin-left:auto;
    margin-right:auto;
    background-color:$primary-color;
    border-radius:100%;
    display:block;
    animation:loader .8s infinite ease-in-out;
}
.c-loader.c-loader-white {
    background-color: #fff;
}

// Rotating circle
@keyframes sk-circleFadeDelay{
    0%, 39%, 100%{ opacity:0; }
    40%{ opacity:1; }
}
.c-fading-circle-wrapper{
    padding:1.6rem 0;
    display:flex;
    justify-content:center;
    align-items:center;
}

.c-fading-circle-text{
    padding-left:1.5rem;
    flex-grow:0;
    flex-shrink:0;
    flex-basis:auto;
}

.c-fading-circle{
    width:2.4rem;
    height:2.4rem;
    position:relative;
    flex-grow:0;
    flex-shrink:0;
    flex-basis:auto;
}

.c-circle{
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    &::before{
        width:15%;
        height:15%;
        margin:0 auto;
        content:'';
        background-color:$primary-color;
        border-radius:100rem;
        display:block;
        animation:sk-circleFadeDelay 1.2s infinite ease-in-out both;
    }
}

.c-form{
    .c-circle{
        &::before{
            background-color:$white;
        }
    }
}

.c-fading-circle{
    .c-circle-2{
        transform:rotate(30deg);
        &::before{
            animation-delay:-1.1s;
        }
    }
    .c-circle-3{
        transform:rotate(60deg);
        &::before{
            animation-delay:-1s;
        }
    }
    .c-circle-4{
        transform:rotate(90deg);
        &::before{
            animation-delay:-0.9s;
        }
    }
    .c-circle-5{
        transform:rotate(120deg);
        &::before{
            animation-delay:-0.8s;
        }
    }
    .c-circle-6{
        transform:rotate(150deg);
        &::before{
            animation-delay:-0.7s;
        }
    }
    .c-circle-7{
        transform:rotate(180deg);
        &::before{
            animation-delay:-0.6s;
        }
    }
    .c-circle-8{
        transform:rotate(210deg);
        &::before{
            animation-delay:-0.5s;
        }
    }
    .c-circle-9{
        transform:rotate(240deg);
        &::before{
            animation-delay:-0.4s
        }
    }
    .c-circle-10{
        transform:rotate(270deg);
        &::before{
            animation-delay:-0.3s
        }
    }
    .c-circle-11{
        transform:rotate(300deg);
        &::before{
            animation-delay:-0.2s
        }
    }
    .c-circle-12{
        transform:rotate(330deg);
        &::before{
            animation-delay:-0.1s
        }
    }
}