/**
 * Flex grid
 * @path: objects/grid
 */

.o-flex-row {
    @include flex-grid-row;
}

.o-column-gap {
    gap: 3.2rem;
}

.o-nowrap-large-up {
    flex-wrap: wrap; 

    & > *{
        flex-basis: 100%;
    }
}

@include breakpoint(medium) {
    .o-flex-row {
        flex-wrap: nowrap;
    }
}

@include breakpoint(large) {
    .o-nowrap-large-up {
        flex-wrap: nowrap;

        & > *{
            flex-basis: auto;
        }
    }
}