/**
 * Headings
 * @path: components/headings
 */

/**
 * Creates helper classes for heading styles
 * 
 * @var {Map} $headings - settings/custom
 * @output e.g. '.u-heading-small'
 */
@each $breakpoint, $headers in $headings {
    @include breakpoint($breakpoint){
        @each $header, $styles in $headers{
            #{$header}{
                @each $property, $value in $styles{
                    #{$property}:#{$value};
                }
            }
        }
    }
}

[class*="c-heading-"]{
    small{ 
        font-size:70%; 
        line-height:1.5;
        display:inline-block;
    }
}