/**
 * Sticky navigation
 * @path: components/sticky-nav
 */

.c-sticky-nav-wrapper {
    height: 12.5rem;
}

.c-sticky-nav{
    width: 100%;
    position: static;
    top: -8rem;
    transition: top .4s ease-out;
    background-color: $white;
    box-shadow:  0px 6px 80px 0px rgba(0,0,0,0.4);
    &.is-fixed{
        position: fixed;
        top: 0;
        transition: top .4s ease-in;
    }
}

@include breakpoint(large) {
    .c-sticky-nav-wrapper {
        height: 17.5rem;
    }

    .c-sticky-nav {
        top: -17.5rem;
    }
}