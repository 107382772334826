/**
 * Colors
 * @path: utilities/colors
 */

/**
 * Creates helper classes for colors
 * 
 * @var {Map} $foundation-palette - settings/foundation
 * @output e.g. '.u-color-primary'
 */

@each $name, $value in $foundation-palette{
    .u-color-#{$name}{
        color:$value;
    }
    .u-color-hover-#{$name}{
        &:hover{
            color:$value;
        }
    }
    .u-bg-#{$name}{
        background-color:$value;
        &.c-button:hover{
            background-color:scale-color($value, $lightness: -20%);
        }
    }
    .u-fill-#{$name}{
        fill:$value;
    }
    .u-stroke-#{$name}{
        stroke:$value;
    }
    .u-border-#{$name}{
        border-color:$value;
    }
}

.u-bg-transparent{ background-color:transparent; }

// Gradients
.u-gradient-50{
    height:50%;
    position:absolute;
    z-index:-1;
    bottom:0;
    left:0;
    right:0;
    /* Use to edit this gradient: http://colorzilla.com/gradient-editor/#000000+0,f3fafa+100&0+0,0.65+99 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(241,248,248,0.65) 99%, rgba(243,250,250,0.65) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(241,248,248,0.65) 99%,rgba(243,250,250,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(241,248,248,0.65) 99%,rgba(243,250,250,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6f3fafa',GradientType=0 ); /* IE6-9 */
}
