/**
 * Header
 * @path: components/header
 */

.c-main-header{
    height:55vh;
    background-color:$secondary-color;
    position: relative; 
}

.c-header{
    height:22rem;
}

.c-header-trigger{
    @include horizontal-center;
    z-index:5;
    bottom:10rem;
}

.c-header-trigger-button{
    font-family:$header-font-family;
    cursor:pointer;
    &::after{
        font-family:'amsteldijk';
    }
    &:focus{
        outline:none;
    }
    .c-icon{
        display:block;
    }
}

.off-canvas{
    .c-header-logo{
        width:7.5rem;
        margin:auto;
        padding:1rem 0;
        display:block;
    }
}

// 960+
@include breakpoint(medium){
    .c-main-header{
        height:80vh;
    }

    .c-header{
        height:32.5rem;
    }
}

/* Between 640px and 960px wide and below 620px height */
@media screen and (max-height: 620px) and (min-width: 640px) and (max-width: 960px){
    .c-main-header {
        height: 44.5rem;
    }
}

/* 960px and wider and below 805px */
@media screen and (max-height: 805px) and (min-width: 960px){
    .c-main-header {
        height: 66.5rem;
    }
}