/**
 * Squeezebox
 * @path: components/squeezebox
 */
.c-squeezebox{
    @include accordion-container;
    max-width:62.5rem;
    margin:0 auto;
}

.c-squeezebox-item{
    @include accordion-item;
    border-bottom:.1rem solid $white;
    &.is-active{
        .c-squeezebox-title{
            &::after{
                transform:rotate(0) translateY(-50%);
            }
        }
        .c-squeezebox-content{
            display:block;            
        }
    }
}

.c-squeezebox-title{
    @include accordion-title; 
    position:relative;
    &:hover, &:focus{
        color:$accordion-item-color;
    }
    &::after{
        @extend %icons;
        position:absolute;
        top:50%;
        right:1rem;
        font-size:3rem;
        color:$white;
        content: '\6a';
        transform:rotate(-90deg) translateX(50%);
    }
}

.c-squeezebox-content{
    @include accordion-content;
    font-size:1.2rem;
    text-transform:uppercase;
    .c-list-item{
        padding:1rem 0;
        align-items:center;
        &::before{
            @extend %icons;
            width:1.4rem;
            height:1.4rem;
            margin-right:1rem;
            content:'\68';
            font-size:.9rem;
            line-height:1.4rem;
            color:$white;
            border-radius:10rem;
            text-align:center;
            background-color:$primary-color;
            flex:0 0 auto;
        }
    }
}