/**
 * Forms
 * @components/forms
 */

.c-select{
    @include background-arrow;
    &.u-bg-secondary{
        color:$white;
        &:focus{
            background-color:$secondary-color;
        }
    }
    &.u-bg-primary-light{
        color:$white;
        &:focus{
            @extend .u-bg-primary-light;
        }
    }
    &.u-bg-white{
        @include background-arrow($black);
        border:1px solid #aaaaaa;
    }
}

.c-sub-items{
    padding: 0 2.5rem;
}

.c-input{
    &.u-bg-secondary{
        color:$white;
        &:focus{
            background-color:$secondary-color;
        }
    }
    &.u-bg-secondary-dark{
        color:$white;
        &:focus{
            background-color:map-get($foundation-palette, secondary-dark);
        }
    }
    &.u-bg-primary-light{
        color:$white;
        &:focus{
            @extend .u-bg-primary-light;
        }
    }
    &.u-bg-white{
        border:1px solid #aaaaaa;
    }
}

.c-input-number {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}

.c-textarea{
    height:calc( 100% - 2.5rem - #{$form-spacing}) !important;
}

.c-business-form-textarea {
    height: 15.2rem !important;
}

.c-checkbox-wrapper, .c-radio-wrapper{
    margin-bottom:$form-spacing;
}

.c-checkbox{
    position: relative;
    padding-left: 2.5rem;
    input[type=checkbox]{
        position: absolute;
        top: .7rem;
        left: 0;
    }
}

.c-radio{
    position: relative;
    padding-left: 2.5rem;
    input[type=radio]{
        position: absolute;
        top: .7rem;
        left: 0;
    }
}

.c-form-help-text {
    margin-top: -$form-spacing;

    &.is-success {
        color: $success-color;
    }

    &.is-error {
        color: $alert-color;
    }
}

@include breakpoint(medium) {
    .row {
        .c-form {
            .row {
                margin-left: calc(#{map-get($grid-column-gutter, 'medium')} / -4);
                margin-right: calc(#{map-get($grid-column-gutter, 'medium')} / -4);
            }
        }
    }
    
    .c-form {
        .column {
            padding-left: calc(#{map-get($grid-column-gutter, 'medium')} / 4);
            padding-right: calc(#{map-get($grid-column-gutter, 'medium')} / 4);
        }
    }
}