/**
 * Icon fonts
 * @path: components/icons
 * ----------------------
 *
 * Table of Contents:
 *
 * 1. General icons (fontastic.me)
 * 2. Custom icons
 * 3. Lightcase icons
 */

// 1. General icons (fontastic.me)
// ---------------
@font-face{
    font-family:'amsteldijk';
    src:url('../fonts/icons/amsteldijk.eot');
    src:url('../fonts/icons/amsteldijk.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icons/amsteldijk.woff') format('woff'),
        url('../fonts/icons/amsteldijk.ttf') format('truetype'),
        url('../fonts/icons/amsteldijk.svg#amsteldijk') format('svg');
    font-weight:normal;
    font-style:normal;
}

%icons{
    font-family:'amsteldijk' !important;
    font-style:normal !important;
    font-weight:normal !important;
    font-variant:normal !important;
    text-transform:none !important;
    speak:none;
    line-height:1;
    vertical-align:middle;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}

[class^="c-icon-"]::before,
[class*=" c-icon-"]::before{
    @extend %icons;
}

.c-icon-twitter::before{ content:'\61'; }
.c-icon-facebook::before{ content:'\62'; }
.c-icon-linkedin::before{ content:'\63'; }
.c-icon-instagram::before{ content:'\64'; }
.c-icon-pinterest::before{ content:'\65'; }
.c-icon-google-plus::before{ content:'\66'; }
.c-icon-flickr::before{ content:'\67'; }
.c-icon-checkmark::before{ content:'\68'; }
.c-icon-times::before{ content:'\69'; }
.c-icon-angle-down::before{ content: '\6a'; }
.c-icon-play::before{ content: '\6b'; }
.c-icon-arrow-68::before{ content: '\6c'; }
.c-icon-transfer-7::before{ content: '\6d'; }
.c-icon-arrow-up::before{ content: '\6e'; }
.c-icon-arrow-down::before{ content: '\6f'; }

// 2. Custom icons
// ---------------
// Social media
.c-icon-social-media{
    @include icon-circle;
}

// Hole status 
.c-icon-status-active{
    @include icon-circle($bg-color: $secondary-color, $color: $white, $with-text: true);
}

.c-icon-status-inactive{
    @include icon-circle($bg-color: $alert-color, $color: $white, $with-text: true);
}

.c-icon-checkmark,
.c-icon-times {
    display: flex;
}

.c-icon-usp-white {
    @include icon-circle($width: 1.8rem, $height: 1.8rem, $font-size: 1.4rem, $bg-color: $white, $color: $quaternary-color, $with-text: true);
}
 
.c-icon-tooltip {
    width: 1.8rem;
    height: 1.8rem;
    margin-left: .5rem;
    font-size: 1.4rem;
    background-color: $medium-gray;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    border-bottom: 0;
}

// 3. Lightcase icons
// ---------------
@font-face {
    font-family: 'lightcase';
    src: url('#{map-get($lightcase-settings, font-path)}lightcase.eot?55356177');
    src: url('#{map-get($lightcase-settings, font-path)}lightcase.eot?55356177#iefix') format('embedded-opentype'),
        url('#{map-get($lightcase-settings, font-path)}lightcase.woff?55356177') format('woff'),
        url('#{map-get($lightcase-settings, font-path)}lightcase.ttf?55356177') format('truetype'),
        url('#{map-get($lightcase-settings, font-path)}lightcase.svg?55356177#lightcase') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class*='lightcase-icon-']::before{
    font-family: 'lightcase', sans-serif;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
}

/* Codes */
.lightcase-icon-play::before{ content: '\e800'; }
.lightcase-icon-pause::before{ content: '\e801'; }
.lightcase-icon-close::before{ content: '\e802'; }
.lightcase-icon-prev::before{ content: '\e803'; }
.lightcase-icon-next::before{ content: '\e804'; }
.lightcase-icon-spin::before{ content: '\e805'; }