/**
 * Page title
 * @path: components/page-title
 */

.c-page-title{
    @include heading-title;
    color:$white;
    text-transform:uppercase;
    &-main{ 
        margin-bottom:0;
        font-size:4rem; 
    }
    &-sub{
        margin-bottom:2.5rem;
    }
}

// +520
@include breakpoint(medium) {
    .c-page-title{
        &-main{ 
            margin-bottom:2rem;
            font-size:6rem;
        }
    }
}

// +960
@include breakpoint(large) {
    .c-page-title{
        &-main{
            font-size:8rem;
        }
    }
}