/**
 * Grid slot
 */

.is-non-touch{
    .c-grid-slot:not(.is-closed, .is-full):hover{
        background-color:darken($secondary-color, 10%);
    }
}

.c-grid-slot{
    width:100%;
    margin-bottom:1rem;
    padding:1.5rem 0;
    color:$white;
    background-color:$secondary-color;
    border-left:.1rem solid $white;
    border-right:.1rem solid $white;
    cursor:pointer;
    &.is-mine{
        background-color:$primary-color;
    }
    &.is-closed{
        background-color:darken($light-gray, 10%);
        cursor:not-allowed;
    }
    &.is-full{
        background-color:darken($medium-gray, 25%);
        &.is-mine{
            background-color:$primary-color;
        }
    }
    &.is-selected{
        background-color:darken($secondary-color, 10%);
    }
}

.c-grid-slot-legenda-wrapper{
    margin-bottom:2rem;
    .column{
        flex: 0 0 auto;
        max-width: 100%;
    }
}

.c-grid-slot-legenda{
    margin-right:2rem;
    margin-bottom:1rem;
    padding-left:2.5rem;
    position:relative;
    font-size:1.2rem;
    display:block;
    &::before{
        width:2rem;
        height:1.5rem;
        position:absolute;
        top:50%;
        left:0;
        background-color:$secondary-color;
        transform:translateY(-50%);
        content:'';
    }
    &.is-mine{
        &::before{
            background-color:$primary-color;       
        }
    }
    &.is-closed{
        &::before{
            background-color:darken($light-gray, 10%);
        }
    }
    &.is-full{
        &::before{
            background-color:darken($medium-gray, 25%);
        }
        &.is-mine{
            &::before{
                background-color:$primary-color;       
            }
        }
    }
    &.is-selected{
        &::before{
            background-color:darken($secondary-color, 10%);
        }
    }
}

@include breakpoint(medium){
    .c-grid-slot-legenda-wrapper{
        margin-bottom:0;
        justify-content:flex-end;
        .column{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    .c-grid-slot-legenda{ 
        margin-right:0; 
    }
}

@include breakpoint(large){
    .c-grid-slot-legenda-wrapper{
        .column{
            flex: 0 0 auto;
            max-width: 100%;
        }
    }
    .c-grid-slot-legenda{ 
        margin-right:2rem; 
    }
}