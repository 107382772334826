/**
 * Wrappers
 * @path: objects/wrappers
 */

 .o-wrapper{
    width:100%;
}

.o-error-logo-wrapper{
    width: 10.9rem;
}

.o-pro-wrapper{
    left:50%;
    position: relative;
    transform: translateX(-50%); 
}

@include breakpoint(medium){
    .o-pro-wrapper{
        left:0;
        position: relative;
        transform: translateX(0); 
    }
}