/**
 * Panel
 * @path: components/panel
 */

.c-panel {
    margin-bottom: 2.4rem;
    padding: 1.6rem;
    border: .1rem solid $primary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
}