/**
 * Highlight sections
 * @path: components/highlight-section
 */
.c-highlight-image-right{
    position:relative;
    z-index:5;
    .c-paint-spatter-wrapper{
        margin:auto;
        top:60%;
        left:-8rem;
    }
}

.c-highlight-two-images{
    position:relative;
    .c-paint-spatter-wrapper{
        margin:auto;
        top:60%;
        left:-8rem;
    }
}

.c-highlight-image-wrapper{
    @extend .u-elevate;
    padding:0 !important;
    position:relative;
    z-index:-1;
}

 @include breakpoint(medium){
    .c-highlight-image-right{
        .c-paint-spatter-wrapper{
            top:-11.5rem;
            left:-11rem;
        }
        .c-highlight-image-wrapper{
            z-index:-1;
            left:-2.5rem;
        }
    }

    .c-highlight-two-images{
        .c-paint-spatter-wrapper{
            top:80%;
            left:-2rem;
            right:-11rem;
        }
        .c-highlight-image-wrapper:nth-child(1){
            top:-3.5rem;
            right:-2.5rem;
        }
        .c-highlight-image-wrapper:nth-child(3){
            left:-2.5rem;
        }
    }    
}

@include breakpoint(large){

}