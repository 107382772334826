/**
 * Banners
 * @path: components/banner
 */

.c-banner-bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.c-banner-wrapper {
    width: 100%;
    padding: 2.4rem 1.6rem;
}

@include breakpoint(medium) {
    .c-banner-wrapper {
        padding: 5.6rem 4.8rem;
    }   
}