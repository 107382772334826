/**
 * Links
 * @path: components/links
 */

.c-link{
    border:0;
    border-bottom:.1rem solid transparent;
    transition:border-color .15s ease-in-out;
    display:inline !important;

    &.is-active {
        color: $secondary-color;
    }

    &:hover{
        border-color:$primary-color;
        &.no-border {
            border-color: transparent;
        }
        &.white{
            border-color:$white;
        }
        &.secondary{
            border-color:$secondary-color;
        }
    }
}

.c-link-underline{
    border-color:$primary-color !important;
    &.white{
        color:$white;
        border-color:$white !important;
    }
    &.secondary{
        color:$secondary-color;
        border-color:$secondary-color !important;
    }
    &:hover, &:focus{
        border-color:transparent !important;
    }
}

.c-link-none{
    border-color:transparent !important;
    &:hover, &:focus{
        border-color:$primary-color !important;
        &.white{
            border-color:$white !important;
        }
        &.secondary{
            border-color:$secondary-color!important;
        }
    }
}

.u-bg-secondary, .u-bg-primary{
    .c-link{
        color:$white;
        border-color:$white;
        &:hover{
            border-color:transparent;
        }
        // Buttons
        &.c-button{ 
            border-color:transparent;
            &:hover{
                border-color:$white;
            }
        }
    }
}