/**
 * Avatars
 * @path: components/avatars
 */

.c-avatars { 
    margin-top: 2.4rem;

    img {
        margin-right: .8rem;
    }
}

@include breakpoint(large) {
    .c-avatars { 
        margin-top: 0;
        padding-left: .8rem;
    }
}