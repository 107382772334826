/**
 * Paint spatter
 * @path: components/paint-spatter
 */

.c-paint-spatter-wrapper{
    max-width:45.5rem;
    position:absolute;
    z-index:-1;
    top:0;
    right:-8rem;
    &.v-align-middle{
        top:50%;
        transform:translateY(-50%);
    }
    &.h-align-left{
        left:-20rem;
        right:auto;
    }
    &.h-align-right{
        right:-20rem;
    }
    &.rotate-min-20-deg{
        .c-paint-spatter{ 
            transform:rotateZ(-20deg);
        }
    }
    &.rotate-15-deg{
        .c-paint-spatter{ 
            transform:rotateZ(15deg); 
        }
    }
    .c-paint-spatter{
        width:120%;
    }
}

// 520+
@include breakpoint(medium){
    .c-paint-spatter-wrapper{
        right:-5rem;
    }
}

// 960+
@include breakpoint(large){
    .c-paint-spatter-wrapper{
        top:-7rem;
        right:-17rem;
    }
}