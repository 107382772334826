/**
 * Filter
 * @path: components/filter
 */

.c-filter-wrapper{
    max-width:32rem;
    .column{ 
        padding-left:1.5rem;
        padding-right:1.5rem;
    }
}

.c-filter-collapse{
    width:100%;
    height:4rem; 
    margin-bottom:1rem !important;
    background-color:scale-color( $light-gray, $lightness:25% );
    font-family:$header-font-family;
    text-transform:uppercase;
    overflow:hidden;
    transition:height .3s ease-in-out;
}

.c-filter-head{
    width:100%; 
    height:4rem; 
    padding:0 1.3rem;
    position:relative; 
    z-index:1;
    line-height:4.1rem; 
    cursor:pointer;
    &::after{ 
        @extend %icons;
        position:absolute; 
        z-index:5; 
        top:50%; 
        right:1.5rem;
        font-size:2rem; 
        color:$body-font-color;
        content: '\6a';
        transition:transform .3s ease-in-out;
        transform:translateY(-50%) rotateZ(0deg); 
    }
    &.is-down{
        &::after{
            transform:translateY(-50%) rotateZ(180deg);
        }
    }
}

.c-filter-item{
    line-height:1.25;
}

.c-filter-anchor{
    padding:.7rem 1.3rem;
    color:$dark-gray;
    display:block;
    &.is-active{
        color:$primary-color;
        font-weight:600;
        &:focus{
            color:$primary-color;
            font-weight:600;
        }
    }
    &:focus{
        color:$dark-gray;
        font-weight:600;
        outline:0;
    }
}

@include breakpoint(medium) {
    .c-filter-wrapper{
        max-width:calc( 32rem * 2 );
        margin-bottom:2rem;
        align-items:center;
    }

    .c-filter-collapse{
        height:auto;
        margin-bottom:0 !important;
        background-color:transparent;
        overflow:visible;
        display:flex;
    }

    .c-filter-item{
        flex:1 1 auto;
    }

    .c-filter-anchor{
        padding:1rem 0;
    }

    .c-filter-select{
        margin-bottom:0;
    }
}

@include breakpoint(large) {
    .c-filter-wrapper{
        max-width:calc( 32rem * 3 );
    }

    .c-filter-anchor{
        font-size:1.8rem;
    }
}