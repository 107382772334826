/**
 * Overlay
 * @path: components/overlay
 */

.c-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999; 
    visibility: visible;
    background-color: rgba($primary-color, .5);
    display: flex;
    justify-content: center;
    align-items: center;
}