/**
 * Lists
 * @path: components/lists
 */

.c-list{
    display:block;
    .c-link{
        &::before{
            margin-right:.5rem;
            content:'>';
        }
    }
}

.c-list-unstyled{
    margin:0;
    list-style-type:none;
}

.c-list-inline{
    .c-list-item{
        display:inline-block;
    }
}

.c-list-column{
    column-count:1;
    column-gap:2rem;
    display:block;
    .c-list-item{ 
        padding:.7rem .5rem;
        break-inside:avoid-column;
        -webkit-column-break-inside:avoid;
    }
}

.c-list-bg-hover{
    &:hover{
        background-color:$light-gray;
    }
}

.c-list-flex{
    display:flex;
    .c-list-item{
        flex:1 1 auto;
        padding:1.5rem 1rem;
        &.c-list-col-4{
            flex-basis:25%;
        }
        &.shrink{
            flex:0 1 auto;
        }
        &:first-child{
            flex-basis:11.5rem;
        }
    }
}

.c-featured-list {
    display: flex;
    gap: 1.6rem;
    flex-wrap: wrap;
}

.c-subscription-list {
    margin-top: 2.4rem;

    .c-list-item {
        margin-bottom: 1.6rem;
    }
}

@media screen and (min-width: 480px){
    .c-list-column{
        column-count:2;
    }    
}

// 960+
@include breakpoint(large){
    .c-list-column{
        column-count:3;
    }    

    .c-featured-list {
        gap: 3.2rem;
    }
}