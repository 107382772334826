/**
 * File input
 * @path: components/file-input
 */

 .c-file-input-placeholder {
    @include form-element;
    appearance: none;
}

.c-file-input-button-browse {
    margin: 0 0 $form-spacing;
    padding: $input-padding;
    height: 3.5rem;
    line-height: 1.3;
    cursor: pointer;
    border: 0;
}

.c-file-message {
    cursor: pointer;
}

.zero-height {
    height: 0;
    overflow: hidden;
}

.c-file-input-empty {
    padding: .5rem 0;
    position: relative;
    top: -1.6rem;
    font-size: 1.4rem;
    color: $tertiary-color;
}

.c-loader-wrapper {
    margin: 2rem auto;
    font-size: 1.6rem;
    text-align: center;
    display: none;
    &.is-active{
        display: block;
    }
}

.c-loader {
    width: 3rem;
    height: 3rem;
    position: relative;
    margin: 2rem auto 4rem;
}

.c-loader-dot-inner,
.c-loader-dot-outer {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $primary-color;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 2.0s infinite ease-in-out;
}

.c-loader-dot-outer {
    animation-delay: -1.0s;
}

@keyframes bounce {
    0%, 100% {
        transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
    }
}

