/**
 * USP slider
 * @path: components/usp-slider
 */

.c-usp-slider {
    height: 2.1rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.c-usp-slide {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    float: left;
    text-align: center;
    display: flex;

    span {
        flex-shrink: 0;
    }

    p {
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@include breakpoint(large) { 
    .c-usp-slider {
        height: auto;
        display: flex;
        justify-content: space-evenly;
    }

    .c-usp-slide {
        width: auto;
        float: none;
        flex: 0 1 auto;
        text-align: left;

        p {
            white-space: unset;
        }
    }
}