/**
 * Video wrapper
 * @path: objects/video
 */

.o-embed-wrapper { 
    max-width: 100%; 
    height: 0; 
    position: relative; 
    padding-bottom: 56.25%; 
    overflow: hidden; 
}

.o-embed-wrapper iframe, 
.o-embed-wrapper object, 
.o-embed-wrapper embed { 
    width: 100%; 
    height: 100%; 
    position: absolute; 
    top: 0; 
    left: 0; 
}
