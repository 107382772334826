/**
 * Typography
 * @path: utilities/typography
 */

.u-font-size-small{
    font-size:1.2rem !important; 
}

.u-font-size-medium{
    font-size:1.4rem !important;
}

.u-font-size-large{
    font-size:1.6rem !important;    
}

.u-text-uppercase{
    text-transform:uppercase;
}

.u-text-normal {
    text-transform: none !important;
}

.u-text-recursive {
    font-style: italic;
}

.u-text-nowrap {
    white-space: nowrap;
}

.u-font-bold {
    font-weight: bold;
}