/**
 * Media object (mixins used from Foundation)
 * @path: objects/media-object
 */
.o-media-object {
    @include media-object-container;
    padding-top:2rem;
    border-top:.2rem solid darken($card-background, 5%); 
}

.o-media-object-section {
    @include media-object-section;
}

.o-media-image-wrapper{
    width:4.5rem;
    height:4.5rem;
    margin-right:1rem;   
}