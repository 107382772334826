/**
 * Collage
 * @path: components/collage
 */
.c-collage-container{
    &:hover{
        .c-collage-image{
            img{ 
                transform:scale3d(1.1,1.1,1);
            }
        }
    }
}

.c-collage-wrapper{
    margin-bottom:3rem;
    .column{
        flex-basis:8.2rem;
    }
}
.c-collage-image{
    width:8.2rem;
    height:8.2rem;
    overflow:hidden;
    img{
        transform:scale3d(1,1,1); 
        transition:transform .4s; 
    }
}