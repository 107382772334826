/**
 * Page footer
 * @path: components/footer
 */

.c-footer{
    color:$white;
    font-size:1.3rem;
    a{
        color:$white;
    }
}

.c-footer-content{
    .c-list-item{
        padding-left:1.5rem;
        padding-right:1.5rem;
    }
}

.c-footer-map{
    width:100%;
    height:21.5rem;
    position:relative;
    z-index:1;
    background-image:url('/assets/images/frontend/img_map.jpg');
    background-repeat:no-repeat;
    background-position:center center;
    .c-button{
        position:absolute;
        z-index:5;
        bottom:-2.8rem;
        left:50%;
        transform:translateX(-50%);
    }
}