/**
 * Alert boxes
 * @path: components/alert-box
 */

.alert-box{
    @include alert;
    .close{ @include alert-close; }
    &.radius{border-radius:$alert-radius;}
    &.primary{ @include alert-style($primary-color); }
    &.secondary{ @include alert-style($secondary-color); }
    &.success{ @include alert-style($success-color); }
    &.alert{ @include alert-style($alert-color); }    
    &.warning{ @include alert-style($warning-color); } 
    &.alert-close{opacity:0;}
    ul{margin-bottom:0;}
    a{
        color:$white; text-decoration:underline;
        &:hover{text-decoration:none;}
    }
}