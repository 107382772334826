/*
 * GENERAL CLASSES 
 *
 * In many cases you'll need the same css multiple times for different elements throughout your website.
 * Place the classes with those css in this file.
 */

select{ appearance: none !important; }
a:focus{ outline:0; }
strong{ font-weight:600; }
ul{ display:inline-block; }
a, button{ transition:background-color 0.25s ease-out, color 0.25s ease-out; cursor: pointer; }