/**
 * Tabs (Foundation)
 * @path: components/tabs
 */
.c-tabs{
    border-bottom:.1rem solid $medium-gray;
    display:block;
}

.c-tabs-title{
    border:.1rem solid transparent;
    border-bottom:0;
    &.is-active{
        position:relative;
        border-color:$medium-gray;
        &::after{
            width:100%;
            height:.1rem;
            position:absolute;
            bottom:-.1rem;
            background-color:$light-gray;
            content:'';
        }
    }
    > a{
        padding:1rem .8rem;
        font-family:$header-font-family;
        font-size:1.6rem;
        text-transform:uppercase;
    }
}

.c-tabs-content{
    border-bottom:.1rem solid $medium-gray;
}

.c-tabs-panel{
    padding:2.5rem 0;
}

@include breakpoint(medium) {
    .c-tabs-title{
        > a{
            padding:1.5rem;
            font-size:1.8rem;
        }
    }
}

@include breakpoint(large) {
 
    .c-tabs-title{
        > a{
            padding:1.5rem 2rem;
            font-size:2.2rem;
        }
    }   

    .c-tabs-content{
        padding:2.5rem 0;
    } 
}