/**
 * Cookie
 * @path: components/cookie
 */
.c-cookie {
    width: 90%;
    height: auto;
    padding: map-get($grid-column-gutter, small) / 2;
    background-color: $primary-color;
    align-self: center;

    .c-cookie-switch-wrapper {
        gap: 2rem;

        .switch-paddle {
            font-size: 1.2rem;
        }
    }

    .c-cookie-buttons-wrapper {
        display: flex;
        flex-direction: column;
    }

    .c-cookie-switch-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .c-button {
        margin-bottom: 1rem;

        &:not(:first-child) {
            margin-left: .8rem;
        }
    }
}

@include breakpoint(medium) {
    .c-cookie {
        width: 60%;
        padding: map-get($grid-column-gutter, medium) / 2;
    }
}

@include breakpoint(large) {
    .c-cookie {
        width: 40%;
        max-width: 70rem;

        .c-cookie-buttons-wrapper {
            justify-content: end;
            flex-direction: row;
        }
        
        .c-button {
            margin-bottom: 0;
        }
    }
}