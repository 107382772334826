/**
 * Lightcase
 * @path: components/lightcase
 * @credits: http://cornel.bopp-art.com/lightcase/documentation/
 */

/* Custom video icon */
.c-lightcase-anchor{
    max-width: 30rem;
    margin: 3rem auto 2rem auto;
    position:relative;
    z-index:1;
    display:block;
    &::before{
        position:absolute;
        z-index:10;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background-color:rgba($black, 0.2);
        content:'';
    }
    .c-lightcase-play-icon{
        position:absolute;
        z-index:15;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        &::before{
            font-size:4rem;
            color:$white;
        }
    }
}

/* Global*/
// Styles and overrides for all elements out of the lightcase scope
.lightcase-open {
    body {
        overflow: hidden;
        // For mobile (touch) devices
        .lightcase-isMobileDevice & {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

/* Case */
#lightcase-case {
    display: none;
    position: fixed;
    z-index: 2002;
    top: 50%;
    left: 50%;
    font-family: arial, sans-serif;
    font-size: 13px;
    line-height: 1.5;
    text-align: left;
    text-shadow: #{map-get($lightcase-settings, 'text-shadow')};
    // Only for type: inline, ajax
    html[data-lc-type=inline] &,
    html[data-lc-type=ajax] & {
        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            margin: 0 !important;
            padding: 55px 0 70px 0;
            width: 100% !important;
            height: 100% !important;
            overflow: auto !important;
        }
    }
}

/* Content */
#lightcase-content {
    // Not type: error
    html:not([data-lc-type=error]) & {
        @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
            position: relative;
            z-index: 1;
            text-shadow: none;
            background-color: #{map-get($lightcase-settings, 'case-background')};
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
            -webkit-backface-visibility: hidden;
        }
    }
    // Type: image + video
    html[data-lc-type=image] &,
    html[data-lc-type=video] & {
        @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
            background-color: #{map-get($lightcase-settings, 'case-background-media')};
        }
    }
    // Only for type: inline, ajax, error
    html[data-lc-type=inline] &,
    html[data-lc-type=ajax] &,
    html[data-lc-type=error] & {
        box-shadow: none;
        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            @include clear(true);
        }
        .lightcase-contentInner {
            box-sizing: border-box;
            @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
                padding: 15px;
                &, & > * {
                    width: 100% !important;
                    max-width: none !important;
                }
                // Set auto height to each element except iframes, because of that
                // those cannot have a natural/native height and it must be especially defined.
                & > *:not(iframe) {
                    height: auto !important;
                    max-height: none !important;
                }
            }
        }
    }
    .lightcase-contentInner {
        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            // For mobile (touch) devices
            html.lightcase-isMobileDevice[data-lc-type=iframe] & iframe {
                @include overflow();
            }
            // Type: image + video
            html[data-lc-type=image] &,
            html[data-lc-type=video] & {
                @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
                    line-height: 0.75;
                }
            }
        }
        html[data-lc-type=image] & {
            position: relative;
            overflow: hidden !important;
        }
        // Note: This is a very special wrap only added for few
        // types of content, type 'inline' and 'ajax'.
        .lightcase-inlineWrap {
            // Only for type: inline, ajax error
            html[data-lc-type=inline] &,
            html[data-lc-type=ajax] &,
            html[data-lc-type=error] & {
                @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
                    @include clear(true);
                }
            }
            // Not type: error
            html:not([data-lc-type=error]) & {
                @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
                    padding: 30px;
                    overflow: auto;
                    box-sizing: border-box;
                }
            }
        }
    }
    // Content - Extend with further selectors and colors in case you have
    // elements like <ul>, <li>, <span> or other as part of content within lightcase.
    h1, h2, h3, h4, h5, h6, p {
        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            color: #{map-get($lightcase-settings, 'text-color-viewport-small')};
        }
        @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
            color: #{map-get($lightcase-settings, 'text-color-viewport-large')};
        }
    }
}

/* Info */
#lightcase-info {
    // You can change to position "static" or "relative" instead, but consider
    // that this height will be integrated and calculated
    // to the maximum height additionally to the image height.
    position: absolute;
    padding-top: 15px;
    #lightcase-title,
    #lightcase-caption {
        margin: 0;
        padding: 0;
        line-height: 1.5;
        font-weight: normal;
        text-overflow: ellipsis;
        font-family:$body-font-family;
    }
    #lightcase-title {
        font-size: #{map-get($lightcase-settings, 'title-font-size')};
        color: #{map-get($lightcase-settings, 'title-color')};
        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            position: fixed;
            top: 10px;
            left: 0;
            max-width: 87.5%;
            padding: 5px 15px;
            background: #{map-get($lightcase-settings, 'overlay-color')};
        }
    }
    #lightcase-caption {
        clear: both;
        font-size: #{map-get($lightcase-settings, 'caption-font-size')};
        color: #{map-get($lightcase-settings, 'caption-color')};
    }
    #lightcase-sequenceInfo {
        font-size: #{map-get($lightcase-settings, 'sequenceInfo-font-size')};
        color: #{map-get($lightcase-settings, 'sequenceInfo-color')};
    }
    @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
        .lightcase-fullScreenMode & {
            padding-left: 15px;
            padding-right: 15px;
        }
        // Not type: image, video, flash or error
        html:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) & {
            position: static;
        }
    }
}

/* Loading */
#lightcase-loading {
    @include icon(#fff);
    position: fixed;
    z-index: 2001;
    top: 50%;
    left: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
    opacity: 1;
    font-size: 32px;
    text-shadow: 0 0 15px #fff;
    // Fixes the icon wobble issue in firefox
    -moz-transform-origin: 50% 53%;
    animation: lightcase-spin 0.5s infinite linear;
}

/* Navigation */
// Generic styles for all navigation links
// Generic styles for all navigation links
a[class*='lightcase-icon-'] {
    @include icon-hover(#{map-get($lightcase-settings, 'icon-color')}, #{map-get($lightcase-settings, 'icon-color-hover')});
    outline: none;
    cursor: pointer;
    // Hover status for mobile (touch) devices
    .lightcase-isMobileDevice & {
        &:hover {
            color: #aaa;
            text-shadow: none;
        }
    }
    // Close button
    &.lightcase-icon-close {
        position: fixed;
        top: 15px;
        right: 15px;
        bottom: auto;
        margin: 0;
        opacity: 0;
        outline: none;
    }
    // Prev button
    &.lightcase-icon-prev {
        left: 15px;
    }
    // Next button
    &.lightcase-icon-next {
        right: 15px;
    }
    // Play/Pause button
    &.lightcase-icon-pause,
    &.lightcase-icon-play {
        left: 50%;
        margin-left: -0.5em;
        @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
            opacity: 0;
        }
    }
    @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
        bottom: 15px;
        font-size: 24px;
    }
    @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
        bottom: 50%;
        margin-bottom: -0.5em;
        &:hover,
        #lightcase-case:hover ~ & {
            opacity: 1;
        }
    }
}

/* Overlay */
#lightcase-overlay {
    display: none;
    width: 100%;
    min-height: 100%;
    position: fixed;
    z-index: 2000;
    top: -9999px;
    bottom: -9999px;
    left: 0;
    background: #{map-get($lightcase-settings, 'overlay-color')};
    @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
        opacity: 1 !important;
    }
}

/* Animation */
// Spin animation
@-webkit-keyframes lightcase-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@-moz-keyframes lightcase-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@-o-keyframes lightcase-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@-ms-keyframes lightcase-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@keyframes lightcase-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@include breakpoint(large){
    .c-lightcase-anchor{
        margin: 0;
        float: right;
    }
}