/**
 * Accordion
 * @path: components/accordion
 */

.c-accordion-item{
    &.is-active{
        .c-accordion-content{
            display:block;
        }        
    }
    .c-accordion-content{
        display:none;
    }
}

.c-accordion-header{
    position:relative;
    z-index:1;
}

.c-accordion-divider{
    max-width:99rem;
    margin:auto;
    border-color:$tertiary-color;
}

.c-accordion-trigger{
    &:focus{
        outline:none;
    }
}

/* Hole info */
.c-accordion-holes, .c-accordion-events{
    .c-accordion-trigger{
        margin-bottom:0 !important;
    }
    .c-accordion-content{
        color:$white;
        /* Edit this gradient: http://colorzilla.com/gradient-editor/#003724+0,147860+100 */
        background: $primary-color; /* Old browsers */
        background: -moz-linear-gradient(45deg, $primary-color 0%, $tertiary-color 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, $primary-color 0%, $tertiary-color 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, $primary-color 0%, $tertiary-color 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    .c-accordion-inner-content{
        /* Edit this gradient: http://colorzilla.com/gradient-editor/#999999+0,000000+20,000000+80,999999+100&0.65+0,0+20,0+80,0.65+100 */
        background: -moz-linear-gradient(top, rgba($black,.3) 0%, rgba($black,0) 20%, rgba($black,0) 80%, rgba($black,.3) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba($black,.3) 0%,rgba($black,0) 20%,rgba($black,0) 80%,rgba($black,.3) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba($black,.3) 0%,rgba($black,0) 20%,rgba($black,0) 80%,rgba($black,.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    .c-accordion-image.is-image{
        max-width:30rem;
        margin-top:3rem;
        margin-bottom:2rem;
    }

    .c-accordion-iframe{
        width:100%;
        height:0;
        margin:auto;
        padding-bottom:56.25%;
        position:relative;
        z-index:1;
        iframe{
            width:100%;
            height:100%;
            position:absolute;
            z-index:5;
            top:0;
            left:0;
            border:0;
        }
    }
}
/* Bookings */
.c-accordion-bookings{
    .c-accordion-item{
        &.is-active{
            .c-accordion-trigger{
                transform:rotate(180deg);
            }
        }
    }
    .c-accordion-header{
        padding:3rem 0;
        border-bottom:.1rem solid $medium-gray;
    }
    .c-accordion-trigger{
        padding:.5rem 1rem;
        cursor:pointer;
        .c-icon-angle-down{
            &::before{
                color:$secondary-color;
                font-size:2rem;
            }
        }
    }
    .c-accordion-content{
        position:relative;
        z-index:1;
        &::before, &::after{
            width:100%;
            height:7rem;
            position:absolute;
            z-index:-1;
            content:'';
        }
        &::before{
            top:0;
            /* Edit this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
            background: -moz-linear-gradient(top, rgba(229,229,229,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(229,229,229,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(229,229,229,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        &::after{
            bottom:0;
            /* Edit this gradient: http://colorzilla.com/gradient-editor/#000000+0,d8d8d8+100&0+0,0.65+100 */
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(229,229,229,0.65) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(229,229,229,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(229,229,229,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
    }
}

// 960+
@include breakpoint(large){
    .c-accordion-holes{
        .c-accordion-trigger{
            position:absolute;
            top:0;
            left:get-gutter(medium);
            z-index:15;
        }

        .c-accordion-header{
            padding-bottom:5.6rem;
        }

        .c-accordion-inner-content{
            padding-bottom:5.6rem; 
        }

        .c-accordion-image.is-image{
            margin-top:0;
            margin-bottom:0;
        }

        .c-accordion-iframe{
            max-width:30rem;
            float:right;
        }
    }

}