/**
 * Helpers for flexbox
 * @path: utilities/flex-helpers
 */

.u-gap-1 {
    gap: .8rem;
}

.u-gap-2 {
    gap: 1.6rem;
}

.u-gap-3 {
    gap: 2.4rem;
}

.u-flex-wrap {
    flex-wrap: wrap;
}

.u-flex-grow {
    flex: 1 0 auto;
}

@include breakpoint(medium){
    .u-shrink-medium{
        flex:0 1 auto !important; 
    }
}