/**
 * Navigation bar
 * @path: components/navigation
 */

// Hover styling for non-touch
.is-non-touch{
    .c-nav-anchor:hover{ color:$secondary-color }
}

// Mobile navigation icon
.c-nav-icon{
    width:2.5rem;
    height:5.6rem;
    margin:0;
    padding:1.5rem 0;
    position:relative;
    cursor:pointer;
    border:0;
    &:focus{ outline:0; }
}

.c-nav-icon-inner{
    top:1.7rem;
    left:0;
    transition:background-color 0s linear .13s;
    display:block;
    &, &::after, &::before{
        width:2.5rem;
        height:.3rem;
        position:absolute;
        background-color:$primary-color;
        border-radius:1rem;
        display:block;
        content:'';
    }
    &::before{
        top:1rem;
        transition:top .1s cubic-bezier(.33333,.66667,.66667,1) .2s, transform .13s cubic-bezier(.55,.055,.675,.19);
    }
    &::after{
        top:2rem;
        transition:top .2s cubic-bezier(.33333,.66667,.66667,1) .2s, transform .13s cubic-bezier(.55,.055,.675,.19);
    }
    &.is-active{
        background-color:transparent;
        transition-delay:.22s;
        &::before{
            top:0;
            transition:top .1s cubic-bezier(.33333,0,.66667,.33333) .15s, transform .13s cubic-bezier(.215,.61,.355,1) .22s;
            transform:translate3d(0,10px,0) rotate(45deg);
        }
        &::after{
            top:0;
            transition:top .2s cubic-bezier(.33333,0,.66667,.33333), transform .13s cubic-bezier(.215,.61,.355,1) .22s;
            transform:translate3d(0,10px,0) rotate(-45deg);
        }
    }
}

// Top nav bar
.c-header-nav{
    height: 12.5rem;
    position: relative;
    top:0;
    right:0;
    left:0;
    z-index:100;
    background-color:$white;
    transition:height .3s ease-in;
}

.c-header-nav-logo{
    width:11.5rem;
    height:6.4rem;
    margin:1rem 0;
    display:block;
    &:focus{ outline:0; }
}

// Main and sub navigation
.c-nav {
    width: 100%;
    margin: 0;
    font-family: $header-font-family;
    text-transform: uppercase;
    list-style-type: none;
    display: flex;
    align-items: center;

    &.vertical {
        flex-wrap: wrap;

        .c-nav-item {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

.c-nav-anchor {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.is-parent {
        margin-right: 1.2rem;
        position: relative;

        &::after {
            @extend %icons;

            position: absolute;
            right: -1.6rem;
            font-size: 1rem;
            content: '\6c';
            transform: rotate(90deg);
        }
    }

    &.is-active {
        color: $secondary-color;
    }

    &:focus {
        outline: 0;
    }
}

.off-canvas {
    .c-nav-sub {
        .c-nav {
            padding-top: 1.6rem;
            padding-bottom: 1.6rem;
        }

        .c-nav-anchor {
            font-size: 1.8rem;
        }
    }
    .c-nav-anchor {
        padding-top: .4rem;
        padding-bottom: .4rem;
    }
    .c-nav-top {
        padding-top: 1rem;
        padding-bottom: 1rem;

        .c-nav-item:last-child {
            .c-button {
                margin-top: 1.6rem;
            }
        }
    }
}

.c-nav-top{
    .c-nav-anchor{
        font-size:1.6rem;
        color:$dark-gray;
    }
}

.c-nav-main{
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;

    .c-nav-anchor {
        font-size: 2.2rem;
    }
}

.c-nav-sub{
    display: none;

    .c-nav {
        flex-wrap: wrap;

        .c-nav-item {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .c-nav-anchor {
        &.is-active{
            border-bottom: .2rem solid $secondary-color;
        }
    }
}

.c-nav-devider{
    margin-top:0;
    margin-bottom:.7rem;
    border-color:$primary-color;
}

// 960+
@include breakpoint(large) {
    // Desktop navigation
    .c-nav-main {
        .c-nav {
            justify-content: center;
        }

        .c-nav-item {
            position: relative;
            z-index: 1;

            &:first-child {
                margin-left: 0;

                .c-nav-anchor {
                    padding-left: 0;
                }
            }

            &:last-child {
                margin-right: 0;

                .c-nav-anchor:not(.is-parent) {
                    padding-right: 0;
                }
            }

            &:hover {
                .c-nav-sub {
                    display: block;
                }
            }
        }

        .c-nav-anchor {
            font-size: 2rem;
            padding-left: 1.2rem;
            padding-right: 1.2rem;

            &.is-parent {
                &::after {
                    right: 0;
                }
            }
        }
    }

    // Top nav bar
    .c-header-nav{
        height: auto;
    }

    .c-nav-top {
        .c-nav {
            justify-content: flex-end;
        }

        .c-nav-anchor {
            padding-left: .8rem;
            padding-right: .8rem;
        }

        .c-button {
            margin-left: .8rem;
        }
    }

    .c-nav-sub{
        position: absolute;
        z-index: 5;
        top: 2.8rem;
        background-color: $white;

        .c-nav {
            padding: 1.6rem;
        }

        .c-nav-item {
            margin: 0;
        }

        .c-nav-anchor{
            padding: 0;
            font-size: 1.6rem;
            border-bottom: .2rem solid transparent;
            white-space: nowrap;
        }
    }
}