/**
 * Custom settings
 * @path: settings/custom
 * ----------------------
 *
 * Table of Contents:
 *
 * 1. Font-family
 * 2. Headings
 * 3. Spacing
 * 4. Colors
 * 5. Visuals
 * 6. Lightcase
 */

// 1. Font
// ---------------
// Import font-families
@font-face {
    font-family: 'Unica One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/unica-one/UnicaOne-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
}

// Set body font-size to 16 pixels
$base-font-size: 14px;

// The length unit in which to output vertical rhythm values.
// Supported values: px, em, rem.
$base-unit: 'rem';

// 2. Headings
// ---------------
$headings: (
    small: (
        '.c-heading-xxlarge': ('font-size': '3rem', 'text-transform': 'uppercase', 'font-family': '"Unica One", sans-serif'),
        '.c-heading-xlarge': ('font-size': '2.4rem', 'text-transform': 'uppercase', 'font-family': '"Unica One", sans-serif'),
        '.c-heading-large': ('font-size': '1.9rem', 'text-transform': 'uppercase', 'font-family': '"Unica One", sans-serif'),
        '.c-heading-medium': ('font-size': '1.8rem', 'text-transform': 'uppercase', 'font-family': '"Unica One", sans-serif'),
        '.c-heading-small': ('font-size': '1.6rem', 'text-transform': 'uppercase', 'font-family': '"Unica One", sans-serif'),
    ),
    medium: (
        '.c-heading-xxlarge': ('font-size': '3.4rem'),
        '.c-heading-xlarge': ('font-size': '2.6rem'),
        '.c-heading-large': ('font-size': '2.2rem'),
        '.c-heading-medium': ('font-size': '2.0rem'),
        '.c-heading-small': ('font-size': '1.7rem'),
    ),
    large: (
        '.c-heading-xxlarge': ('font-size': '3.8rem'),
        '.c-heading-xlarge': ('font-size': '2.8rem'),
        '.c-heading-large': ('font-size': '2.5rem'),
        '.c-heading-medium': ('font-size': '2.2rem'),
        '.c-heading-small': ('font-size': '1.8rem'),
    )
);

// 3. Sections
// ---------------
$v-spacing: (
    small: (
        'default' : '7.5rem',
        'tiny' : '1rem',
        'small' : '3rem',
        'medium' : '5rem',
        'large' : '8rem',
        'xxlarge' : '22.5rem'
    ),
    medium: (
        'default' : '8.5rem',
        'small' : '3.3rem',
        'medium' : '5.5rem',
        'large' : '10rem',
        'xxlarge' : '24.5rem'
    ),
    large: (
        'default' : '10rem',
        'small' : '3.5rem',
        'medium' : '6rem',
        'large' : '14rem',
        'xxlarge' : '29rem'
    )
);

// 4. Colors
// ---------------
$tertiary-color: #147860;
$quaternary-color: #85C340;

// 5. Visuals
// ---------------
$visuals: (
    position: (
        'center-bottom' : 'center bottom',
        'center-top' : 'center top',
        'center-center' : 'center center',
        'left-center' : 'left center',
        'right-center' : 'right center'
    ),
    size: (
        'cover' : 'cover',
        'stretch' : '100%'
    )
);

// 6. Lightcase
// ---------------
/**
 * Lightcase settings
 *
 * Note: Override default settings for your project without touching this source code by simply
 * defining those variables within a SASS map called '$lightcase-custom'.
 *
 * // Example usage
 * $lightcase-custom: (
 *   'breakpoint': 768px
 * );
 */
$lightcase-default: (
    'font-path': '../fonts/icons/', // Path relative to the compiled css file
    'breakpoint': 60rem,
    'overlay-color': #000000,
    'case-background': transparent,
    'case-background-media': transparent,
    'icon-font-size': 3.8rem,
    'icon-color': #ffffff,
    'icon-color-hover': scale-color(#ffffff, $lightness: -35%),
    'title-font-size': 1.4rem,
    'title-color': #ffffff,
    'caption-font-size': 1.4rem,
    'caption-color': #ffffff,
    'sequenceInfo-font-size': 1.2rem,
    'sequenceInfo-color': #ffffff,
    'text-shadow': none,
    'text-color-viewport-small': #ffffff,
    'text-color-viewport-large': #ffffff
);

$lightcase-custom: ()!default;
$lightcase-settings: map-merge($lightcase-default, $lightcase-custom);