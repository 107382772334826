/**
 * Visuals
 * @path: components/visuals
 */

/** 
 * Create classes to set different properties of the visuals
 *
 * @var {String} $-zf-size - https://github.com/zurb/foundation-sites/blob/ce4e8d131d676ed30c8e43297b801172572156bc/scss/util/_breakpoint.scss
 * @var {Map} $visuals - settings/custom
 * @output [position] e.g. '.c-visual-small-center-bottom'
 * @ouput [size] e.g. '.c-visual-small-cover'
 */
@include -zf-each-breakpoint{
    @include breakpoint($-zf-size){
        @each $property, $values in $visuals{
            @each $key, $value in $values{
                @if $property == position{
                    .c-visual-#{$-zf-size}-#{$key}{ background-position:#{$value}; }
                }
                @if $property == size{
                    .c-visual-#{$-zf-size}-#{$key}{ background-size:#{$value}; }
                }
            }
        }
    }
}

.c-visual{ 
    background-repeat:no-repeat;
    -webkit-backface-visibility:hidden;
    &.c-visual-form{
        width:100%; 
        height:40rem;
        position:absolute;
        z-index:-1;
        bottom:0;
    }
    &.c-visual-card-grid{
        width:100%; 
        height:58rem;
        position:absolute;
        z-index:-1;
        bottom:0;   
    }
    &.c-visual-section{
        width:100%;
        height:44rem;
        position:absolute;
        z-index:-1; 
        top:-20rem;
    }
}

@include breakpoint(medium){
    .c-visual{
        &.c-visual-form{
            height:72rem;
        }
    }
}

@include breakpoint(large){
    .c-visual{
        &.c-visual-card-grid{
            height:auto;
            top:47rem;
        }
        &.c-visual-section{
            height:64rem;
        }
    }
}