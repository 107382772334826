/*
 * OVERWRITES 
 *
 * Sometimes using your own classes to style Foundation components isn't enough.
 * You'll need to overwrite the default style of Foundation by using their classes.
 * Place those classes in this file.
 */

/* zf Switch */
.switch {
    margin-left: 1.6rem;
    margin-right: 1.6rem;

    &.large {
        .switch-paddle {
            font-size: 1rem;
        }
    }

    .switch-paddle{
        .switch-active, 
        .switch-inactive{
            width: 50%; 
            text-align: center;
            display: block;
        }
        
        .switch-active{ 
            left: .15rem; 
        }
        
        .switch-inactive{ 
            right: .15rem; 
        }
    }
}