/**
 * Splash
 * @path: components/splash
 */

.c-splash {
    position: absolute;
    z-index: 1;
    top: -10rem;
    right: -17rem;
    z-index: -1;
}