/**
 * Table
 * @path: components/table
 */

/**
 * Tables
 */
.c-table{
    width:100%;
    font-size: inherit;

    &.is-tinymce {
        overflow-x: auto;
        display: block;
        // This hack is needed so that width and height options in the style attribute are ignored.
        // These style settings are needed for usability reasons in the admin, so they can't be removed there
        width: 100% !important;
        height: auto !important;
        border-collapse: collapse;

        tbody {
            min-width: 60rem;
            width: 100%;
            display: table;

            tr:nth-child(2n) {
                background-color: rgba($primary-color, .1);
            }

            th,
            td {
                padding: 1.2rem 1rem;
                font-family: 'Unica One', sans-serif;
                text-transform: uppercase;
                font-size: 1.8rem;
            }
        }
    }

    &-subscription {
        width: 100% !important;
        height: auto !important;
        padding-top: 4rem;
        padding-bottom: 1.6rem;
        border-collapse: collapse;
        overflow-x: auto;
        display: block;

        tbody {
            min-width: 70rem;
            width: 100%;
            display: table;
        }

        tr {
            &:nth-child(2n) {
                background-color: rgba($primary-color, .1);
            }

            &:last-child {
                .is-featured {
                    border-bottom: .1rem solid $quaternary-color;
                }
            }

            &:first-child {
                .is-featured {
                    position: relative;
                    z-index: 1;
                }
            }
        }

        th,
        td {
            min-width: 12rem;
            padding: 1.2rem 1rem;
            text-align: left;

            &.is-featured {
                border-left: .1rem solid $quaternary-color;
                border-right: .1rem solid $quaternary-color;
                
            }

            &:first-child {
                min-width: 30rem;
            }

            &:not(:first-child) {
                text-align: center;
            }
        }

        [class*="c-icon-status"] {
            margin: auto;
        }

        .c-table-label {
            width: 100%;
            height: 4rem;
            padding-left: .8rem;
            padding-right: .8rem;
            position: absolute;
            z-index: 5;
            top: -4rem;
            left: 0;
            background-color: $quaternary-color;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &.reservation{
        .c-table-cell{
            padding:1rem 0;

            &.first-cell{
                width:12rem;
            }
        }
    }
}

.c-professional-table{
    width:100%;
    font-size: inherit;
    .c-professional-cell{
        padding-top:.5rem;
    }
}


.c-table-row,
.c-table-head,
.c-table-body {
    max-width:100%;
}

.c-table-cell{
    padding-right:2rem;
    text-align:left;
    vertical-align:top;
    overflow:auto;

    &.first-cell{
        width:9.5rem;
        &.large{
            width: 15.5rem;
        }
    }
    &:last-child{
        padding-right:0;
    }
}


// Admin tables
.table-wrapper{
    td,th{ vertical-align:top; }
}

/**
 * Match information tables
 */
.dataTables_wrapper{
    margin-bottom:4rem !important;
    &:last-child{
        margin-bottom:0 !important;
    }
}
.c-generated-table{
    th{
        text-transform:uppercase;
        font-weight:normal;
        text-align:left;
        &[aria-sort]{
            color:$secondary-color;
        }
        &.sorting, &.sorting_asc, &.sorting_desc{
            background:none !important;
            &::after{
                @extend %icons;
                margin-left:.4rem;
            }
        }
        &.sorting::after{
            content: '\6d';
        }
        &.sorting_asc::after{
            content: '\6e';
        }
        &.sorting_desc::after{
            content: '\6f';
        }
    }
    thead{
        th{
            padding-bottom:2rem;
            &:first-child{
                padding-left:3rem;
                padding-right:1rem;
            }
        }
    }
    // Overwrite code from datatables
    tbody{
        td{
            &:first-child::before{
                background-color:transparent !important;
                border:0 !important;
                box-shadow:none !important;
                font-weight:bold !important;
                color:$body-font-color !important;
                font-size:1.8rem;
            }
        }
    }
    // mobile
    .child{
        ul{
            margin:1.5rem 0 !important;
            display:block !important;
        }
        li {
            border-bottom:.1rem solid $medium-gray !important;

        }
        .dtr-title{
            text-transform:uppercase !important;
        }
    }
}

/* 640+ */
@include breakpoint(medium) {
    /**
     * Results tables
     */
    .c-generated-table{
        thead{
            th{
                &:first-child{
                    padding-left:0;
                    padding-right:0;
                }
            }
        }
    }
}
