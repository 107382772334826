/**
 * Mixins
 * @path: tools/mixins
 *
 * * Table of Contents:
 *
 * 1. Headings
 * 2. Circle icons
 * 3. Alert box
 * 4. Background triangle
 * 5. Lightcase
 * 6. Header overlay
 */

// 1. Headings
// ---------------
@mixin heading-title{
    font-family:$header-font-family;
    display:block;
    line-height:1.2;
}

// 2. Circle icons
// ---------------
@mixin icon-circle(
    $width: 2.6rem,
    $height: 2.6rem,
    $font-size: 1.4rem,
    $bg-color: $white,
    $color: $secondary-color,
    $with-text: false
){
    width: $width;
    height: $height;

    @if( $with-text == true ){
        margin: 0 1rem 0 .3rem;
    } @else{
        margin: 0 .3rem;
    }

    background-color: $bg-color;
    border-radius: 10rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .c-icon{ 
        color: $color; 
        font-size: $font-size;
        flex: 0 0 auto;
    }
}

// 3. Alert box
// ---------------
// Default alert base.
@mixin alert-base {
    border-style: $alert-border-style;
    border-width: $alert-border-width;
    display: block;
    font-weight: $alert-font-weight;
    margin-bottom: $alert-bottom-margin;
    position: relative;
    padding: $alert-padding-top $alert-padding-opposite-direction $alert-padding-bottom $alert-padding-default-float;
    font-size: $alert-font-size;
    transition:opacity $alert-transition-speed $alert-transition-ease;
}

// Add alert styles
@mixin alert-style($bg:$primary-color) {
    // Lightness percentage of the background color.
    $bg-lightness: lightness($bg);

    background-color: $bg;
    border-color: scale-color($bg, $lightness: $alert-function-factor);

    // Text color based on the background color.
    @if $bg-lightness > 70% { color: $alert-font-color-alt; }
    @else                   { color: $alert-font-color; }
}

// We use this to create the close button.
@mixin alert-close {
    font-size: $alert-close-font-size;
    padding: $alert-close-padding;
    line-height: .9;
    position: absolute;
    top: $alert-close-top;
    margin-top: -($alert-close-font-size / 2);
    right: $alert-close-position;
    color: $alert-close-color;
    opacity: $alert-close-opacity;
    background: $alert-close-background;
    &:hover,
    &:focus { opacity: $alert-close-opacity-hover; }
}

// Single mixin to create the alert
@mixin alert($bg:$primary-color, $radius:false) {
    @include alert-base;
    @include alert-style($bg);
}

// 4. Background triangle
// ---------------
/**
 * Adds a downward-facing triangle as a background image to an element. The image is formatted as an SVG, 
 * making it easy to change the color. Because Internet Explorer doesn't support encoded SVGs as background images, 
 * a PNG fallback is also included.
 * There are two PNG fallbacks: a black triangle and a white triangle. The one used depends on the lightness of the input color.
 *
 * @param {Color} $color [$black] - Color to use for the triangle.
 * @param {String} $direction [down] - Direction of the triangle
 */
@mixin background-arrow(
    $color: $white,
    $direction: down
) {
    $rgb: 'rgb%28#{round(red($color))}, #{round(green($color))}, #{round(blue($color))}%29';

    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="21px" height="13px" viewBox="0 0 21 13" style="enable-background:new 0 0 21 13;" xml:space="preserve"><g><path d="M20.8,2.2c0,0.2-0.1,0.3-0.2,0.5L11,12.3c-0.1,0.1-0.3,0.2-0.5,0.2s-0.3-0.1-0.5-0.2L0.4,2.7C0.3,2.5,0.2,2.4,0.2,2.2   c0-0.2,0.1-0.3,0.2-0.5l1-1c0.1-0.1,0.3-0.2,0.5-0.2s0.3,0.1,0.5,0.2l8.1,8.1l8.1-8.1c0.1-0.1,0.3-0.2,0.5-0.2   c0.2,0,0.3,0.1,0.5,0.2l1,1C20.8,1.9,20.8,2,20.8,2.2z"  style="fill: #{$rgb}" /></g></svg>');

    @media screen and (min-width:0\0) {
        @if lightness($color) < 60% {
            // White triangle
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==');
        }
        @else {
            // Black triangle
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMBJREFUeNrEllsOhCAMRVszC9IlzU7KCmVHTJsoMWYMUtpyv9BgbuXQB5ZSdgBYYY4ycgBivk8KYFsQMfMiTTBP4o3nUzCKzOabLJbLy2/g31evGkAginR4/ZegKH5qX3bJCscA3t0x3kgO5tQFyhhFf50xRqFLbyMUNJQzgyjGS/wgCpvKqkRBpuWrE4V9d+1E4dPUXqIg107SQOE/2DRQxMwTDygIInVDET9T3lCoj/6j/VCmGjZOl2lKpZ8AAwDQP7zIimDGFQAAAABJRU5ErkJggg==');
        }
    }
}

// 5. Lightcase
// ---------------
/**
 * Mixin providing icon defaults to be used on top of font-lightcase.
 *
 * Example usage:
 * @include icon(#e9e9e9)
 */
 @mixin icon($color: #fff) {
    position: fixed;
    z-index: 9999;
    width: 1.123em;
    height: auto;
    font-size: #{map-get($lightcase-settings, 'icon-font-size')};
    line-height: 1;
    text-align: center;
    text-shadow: none;
    &, &:focus {
        text-decoration: none;
        color: $color;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: color, opacity, ease-in-out 0.25s;
    }
    & > span {
        display: inline-block;
        text-indent: -9999px;
    }
}

/**
 * Mixin providing icon defaults including a hover status to be used
 * on top of font-lightcase.
 *
 * Example usage:
 * @include icon-hover(#e9e9e9, #fff)
 */
@mixin icon-hover($color: #fff, $color-hover: #f9f9f9) {
    @include icon($color);
    // Default hover status
    &:hover {
        color: $color-hover;
        text-shadow: 0 0 15px $color-hover;
    }
}

/**
 * Provides natural content overflow behavior and scrolling support
 * even so for touch devices.
 *
 * Example usage:
 * @include overflow()
 */
@mixin overflow() {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

/**
 * Neutralizes/resets dimensions including width, height, position as well as margins,
 * paddings and styles. Used to enforce a neutral and unstyled look and behavoir!
 *
 * Example usage:
 * @include clear(true)
 *
 * @param boolean $important
 */
@mixin clear($important) {
    @if $important {
        $important: !important;
    }
    position: relative $important;
    top: auto $important;
    left: auto $important;
    width: auto $important;
    height: auto $important;
    margin: 0 $important;
    padding: 0 $important;
    border: none $important;
    background: none $important;
}

// 6. Header overlay
// ---------------
@mixin header-overlay($color: $black, $opacity: 0.35){
    position:relative;
    z-index:1;
    &::before{
        width:100%;
        height:100%;
        position:absolute;
        z-index:-1;
        content:'';
        background-color:rgba($color, $opacity);
    }
}